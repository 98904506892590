import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import Requests from '../Requests';
import Table from '../components/Table';
import { useParams } from 'react-router-dom';

import ApiClient from '../ApiClient';

import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import Utils from '../utils/Utils';

import SubmitButton from '../components/SubmitButton';
import SessionNav from '../components/SessionNavigation';
import FormDialog from '../components/FormDialog';
import ConfirmationDialog from '../components/ConfirmationDialog';
import DataLoading from '../components/DataLoading';
import List from '../components/List';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import logo from './logo.png';

// All Dialog Data for Adding a new User to the Database
const contextText = 'Fill Out this Form to add a new User';
const dialogTitle = 'Add User';
const homeOwnerText = 'Edit Home Information';

// All Dialog Data for Updating a Users Information
const contextEditText = 'Update Data For ';
const contextEditTitle = 'Edit';
const contextHomeownerEditTitle = 'Edit';

const CLOSE_CONFIRMATION = 'Your changes have not been saved. Closing this window will delete your changes';

let editFields = [];
let homeOwnerFields = [];

let knownFieldData = {
  home1: '',
  home2: '',
  city: '',
  state: '',
  zip: '',
};

// Pre-made dropdowns for choosing a Users Role

const role_entries = ['Homeowner', 'Evaluator', 'Boss Evaluator'];
const roleDropdown = {
  type: 'dropdown',
  label: 'Role',
  entries: role_entries,
  id: 'role',
  required: true
};

const stateDropdown = {
  type: 'dropdown',
  label: 'State',
  entries: Utils.StateAbbreviations,
  id: 'state',
  default: 'NY'
};

const stateDropdown2 = {
  type: 'dropdown',
  label: 'State2',
  entries: Utils.StateAbbreviations,
  id: 'state',
  default: 'NY'
};

const HomeAdd2 = {
  label: 'Home Address 2',
  id: 'home2',
  required: false
};

const HomeAdd22 = {
  label: 'Home Address 2',
  id: 'home22',
  required: false
};

const role_to_id = {
  'Homeowner': 1,
  'Evaluator': 2,
  'Boss Evaluator': 3
};

const DELETE_DIALOG = 'Would you like to remove association between';

const REMOVE_DIALOG = 'Would you like to remove association between';

let theme = createTheme();
theme = responsiveFontSizes(theme);

// ALL TABLE INFORMATION

// Every column in the User table
const cols = ['Last Name', 'First Name', 'Role', 'Phone', 'Email'];

// Every column containing User information.
const home_cols_ho = ['', '', 'Evaluator', 'Address One', 'Address Two', 'City', 'State', 'Zip'];

const home_cols_eval = ['', '', 'Homeowner', 'Address One', 'Address Two', 'City', 'State', 'Zip'];


function createData(email, first, last, phone, role, collapses) {
  return { last, first, role, phone, email, collapses };
}

function createHomeData(name, addr1, addr2, city, state, zip, edit, del, add) {
  return { edit, del, name, addr1, addr2, city, state, zip, add };
}

function createUserHomeLink(first_name, last_name, address_1, city, state, user_id, house_id) {
  return { first_name, last_name, address_1, city, state, user_id, house_id };
}

// END TABLE INFORMATION

let dataChanged = false;

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */
const EvaluatorMainPage = () => {
  // getting route for which list to show
  var { route } = 'homeowners';

  /**
   * All Hooks used on BossEvaluatorMainPage
   *
   * Usages Include:
   * - Updating table data
   * - Updating modal variables
   * - Dynamic Filtering of table
   * - Success messages
   *
   * Note that hooks may fire a page rerender if it is included
   * in a useEffects dependency array.
   *
   */
  const [loaded, setLoaded] = useState(false); // All User information loaded
  const [dataLoaded, setDataLoaded] = useState(false); // Helper for displaying loading skeleton

  const [data, setData] = useState([]); // Data Displayed in the Main Data table
  const [allData, setAllData] = useState([]);
  const [searchData, setSearchData] = useState([]); // All Data filtered out of Main Data Table (used for search bar)
  const [searchVal, setSearchVal] = useState();

  const [open, setOpen] = useState(false); // Open Add User confirmation
  const [editOpen, setEditOpen] = useState(false); // Open edit User Confirmation
  const [editText, setEditText] = useState(''); // contextText for Edit User Modal
  const [updateUserId, setUpdateUserId] = useState(); // Current user being edited (used for autofilling data)
  const [search, setSearchPage] = useState(false); // Remember the page the user was at before searching a user (table pagination.)

  // UNUSED VARIABLES (BUT SET FOR CONVENIENCE)
  const [listData, setListData] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [state, setState] = useState();


  const [openList, setOpenList] = useState(false); // Modal for linking a user to evaluator
  const [currentListSelected, setCurrentListSelected] = useState(); // The current user selected in the link user to evaluator modal
  const [filteredListData, setFilteredListData] = useState([]); // Filtered data elements for link user to evaluator modal (search bar)
  const [evaluatorSelected, setEvaluatorSelected] = useState(); // Current evaluator being linked (evaluator links to homeowner)
  const [linkSuccess, setLinkSuccess] = useState(); // If the link was a success, display a success message

  const [currentRadioFilter, setCurrentRadioFilter] = useState(route);

  const [editHomeOpen, setEditHomeOpen] = useState(false); // Editing a users home (sub-table elements) - will make modal visible.
  const [originalHomeData, setOriginalHomeData] = useState(); // Original home data during edit process to be sent to API.

  const [addHomeOpen, setHomeOpen] = useState(false); // Modal visibility for adding a home to a user (manual address entry)

  const [confirmDelete, setConfirmDelete] = useState(false); // Confirmation displayed when attempting to delete a user
  const [delText, setDelText] = useState(); // Text displayed when attempting to delete a user
  const [delUserId, setDelUserId] = useState(); // The user_id of the current user being deleted
  const [delUserHID, setDelUserHID] = useState();

  const [confirmRemove, setConfirmRemove] = useState(false); // Confirmation for deleting a user house
  const [removeText, setRemoveText] = useState(); // Text for the Confirmation modal
  const [remHomeID, setRemHomeID] = useState(); // house_id being removed
  const [remHomeUserID, setRemHomeUserID] = useState(); // user_id from house being removed

  const [sorted, setSorted] = useState(false); // Whether the data is currently sorted or not
  const [lastSorted, setLastSorted] = useState(false); // Which order the data is currently sorted (used to determine which dir to filter data)

  const [closeConfirmationEditHome, setCloseConfirmationEditHome] = useState(false); // Close confirmation for editing a home
  const [closeConfirmationAddHome, setCloseConfirmationAddHome] = useState(false); // Close confirmation for adding a home
  const [closeConfirmationAddUser, setCloseConfirmationAddUser] = useState(false); // Close confirmation for
  const [closeConfirmationEditUser, setCloseConfirmationEditUser] = useState(false); // Close confirmation for editing a user

  const [notUniqueErr, setNonUniqueErr] = useState(); // When adding a user, if an email is not unique - throw a warning.

  const [api, setApiCall] = useState(false); // Will trigger a loading bar for many components (needs to be passed as prop)

  // Grab Parameters from URL
  const queryParams = new URLSearchParams(window.location.search);
  const userLinkSuccess = queryParams.get('userLinkSuccess');

  // Allow dropdowns to have an onChange callback
  let stateDropdownInput = { ...stateDropdown };
  stateDropdownInput.onInput = onFocusFinish;
  stateDropdownInput.id = 'state';

  let StateDropdown2Input = { ...stateDropdown2 };
  StateDropdown2Input.onInput = onFocusFinish;
  StateDropdown2Input.id = 'state2';

  let homeAdd2Input = { ...HomeAdd2 };
  homeAdd2Input.onInput = onFocusFinish;
  homeAdd2Input.id = 'home2';

  let homeAdd22Input = { ...HomeAdd22 };
  homeAdd22Input.onInput = onFocusFinish;
  homeAdd22Input.id = 'home22';

  // Will define all text fields in a form
  /**
   * Header: Title of each form segment
   * First Name: Users First Name
   * Last Name: Users Last Name
   * Email Address: Users Email Address
   * Phone: Users Phone Number.
   *
   * Data: [Array]
   * Contains: {Object}
   * Each Object May Contain:
   * - Header (Title of the Form segment. Displayed as Typography Header)
   * - Label (Displayed in the form as a TextField MUI Component)
   * - ID (Needs to be Unique, later retrieved from the form)
   * - OnInput (Whenever input is changed)
   * - Required (User needs to input data)
   *
   * Note that each Data [Array] is a separate form
   * - Condition will display a portion of the form iff the dropdown / checkbox condition is met.
   */
  const fields = [
    {
      data: [
        { header: 'User Info' },
        { label: 'First Name', id: 'first', required: true, onInput: onFocusFinish },
        { label: 'Last Name', id: 'last', required: true, onInput: onFocusFinish },
        { label: 'Email Address', id: 'email', required: true, onInput: onFocusFinish },
        { label: 'Phone', id: 'phone', required: true, type: 'phone', onInput: onFocusFinish },
        roleDropdown,
      ]
    },

    {
      data: [
        { header: 'Billing Address Info' },
        { label: 'Home Address 1', id: 'home1', required: true, onInput: onFocusFinish },
        homeAdd2Input,
        { label: 'City', id: 'city', required: true, onInput: onFocusFinish },
        stateDropdownInput,
        { label: 'Zip', id: 'zip', required: true, onInput: onFocusFinish },
        { label: 'address type', id: 'isBilling?', type: 'check' },
      ],
      condition: 'Homeowner',
      visibility: 'Homeowner', // Only Homeowners will See this (an extra condition)
    },

    {
      data: [
        { header: 'Evaluation Address Info' },
        { label: 'Home Address 1', id: 'home12', required: true, ref: useRef() },
        homeAdd22Input,
        { label: 'City', id: 'city2', required: true, ref: useRef() },
        StateDropdown2Input,
        { label: 'Zip', id: 'zip2', required: true, ref: useRef() }
      ],
      condition: 'SameBilling',
      visibility: 'Homeowner', // Only Homeowners will See this (an extra condition)
    }
  ];

  /**
   * Whenever a component in a form loses focus (is clicked off of) this function
   * fires as a callback.
   *
   * @param {*} e Event Handler containing the value of the checkbox.
   * @param {*} id The unique identifier of the textfields
   * @param {*} type Type of component that is changing (ex. TextField, Dropdown, ...)
   * @returns
   */
  function onFocusFinish(e, id, type) {
    const val = e.target.value;

    if (type === 'dropdown')
      return;

    dataChanged = true;

    knownFieldData[id] = val;
  }

  useEffect(() => {
    const fetch = async () => {
      const data = await Utils.fetchSessionData();

      setState(data.state);
      setUserInfo(data.userInfo);
    };

    fetch();
  }, []);

  /**
   * When linking an an evaluator to a homeowner, this function processes the request.
   *
   * @returns
   */
  const onLinkToEvaluatorSubmit = async () => {
    if (!currentListSelected) {
      return;
    }

    setApiCall(true);

    const response = await ApiClient.post(Requests.linkEvaluatorToHome, { home_info: currentListSelected, user_id: evaluatorSelected });
    if (response.status == 200) {
      await fetch_evals();

      setLinkSuccess(true);
      setOpenList(false);
      setApiCall(false);
    }
  };

  function onLinkToEvaluatorClicked(e, user) {
    setCurrentListSelected(user);
  }


  /**
   * Removes the association between a home and an evaluator
   */
  const delUser = async () => {
    const new_rel = { house_id: delUserHID, user_id: userInfo.user_id };

    setApiCall(true);
    const response = await ApiClient.put(Requests.deleteUserHouse, {}, new_rel);

    if (response.status === 200) {
      await fetch_evals();

      setConfirmDelete(false);
      setApiCall(false);
    }
  };

  useEffect(() => {
    handle_radio(null, currentRadioFilter);
  }, [data]);

  const remUserHouse = async () => {
    const info = { user_id: remHomeUserID, house_id: remHomeID };

    setApiCall(true);
    const response = await ApiClient.put(Requests.deleteUserHouse, {}, info);
    if (response.status === 200) {
      await fetch_evals();

      setConfirmRemove(false);
      setApiCall(false);
    }
    setConfirmRemove(false);
  };


  /**
   * A function handler to prompt the client for a homeowners unlinking.
   * @param {*} userInfo
   * @param {*} user_id
   */
  const onDelete = (remUserInfo, user_id, _, house_info) => {
    console.log(remUserInfo, user_id, house_info);

    setDelUserId(user_id);
    setDelUserHID(house_info.house_id);
    setConfirmDelete(true);
    setDelText(DELETE_DIALOG + ` ${remUserInfo.first} ${remUserInfo.last} and ${userInfo.first_name} ${userInfo.last_name}? This doesnt delete the user, just the connection`);
  };

  /**
   * When the Edit link is clicked on a user, this function will autofill all the fields
   * to the users personal information.
   *
   * @param {*} userInfo
   * @param {*} user_id
   * @param {*} _
   * @param {*} home
   */
  const onHomeownerEdit = (userInfo, user_id, _, home) => {
    setOriginalHomeData(home);
    setUpdateUserId(user_id);

    const address_1 = {
      label: 'Address 1',
      default: home.address_1,
      onInput: onFocusFinish,
      id: 'home1',
      required: true
    };

    const address_2 = {
      label: 'Address 2',
      default: home.address_2,
      onInput: onFocusFinish,
      id: 'home2',
    };

    const city = {
      label: 'City',
      default: home.city,
      onInput: onFocusFinish,
      id: 'city',
      required: true,
    };

    const state = {
      type: 'dropdown',
      label: 'State',
      entries: Utils.StateAbbreviations,
      default: home.state,
      onInput: onFocusFinish,
      id: 'state',
      required: true,
    };

    const zip = {
      label: 'Zip',
      default: home.zip,
      onInput: onFocusFinish,
      id: 'zip',
      required: true
    };

    const newHomeownerFields = [
      {
        data: [{ header: 'Address Info' }, address_1, address_2, city, state, zip]
      }
    ];

    homeOwnerFields = newHomeownerFields;

    setEditHomeOpen(true);
  };

  /**
   * A function handler to prompt the client for a users info to be updated.
   * @param {*} userInfo
   * @param {*} user_id
   */
  const onEdit = (userInfo, user_id) => {
    setUpdateUserId(user_id);
    setEditText(contextEditText + userInfo.first + ' ' + userInfo.last);

    const raw_phone = userInfo.phone.replace(/\D/g, '');

    const f_name = {
      label: 'First Name',
      default: userInfo.first,
      id: 'first',
      onInput: onFocusFinish
    };

    const l_name = {
      label: 'Last Name',
      default: userInfo.last,
      id: 'last',
      onInput: onFocusFinish
    };

    const email = {
      label: 'Email Address',
      default: userInfo.email,
      id: 'email',
      onInput: onFocusFinish
    };

    const phone = {
      type: 'phone',
      label: 'Phone',
      default: raw_phone,
      id: 'phone',
      onInput: onFocusFinish
    };

    const role = {
      type: 'dropdown',
      label: 'Role',
      entries: role_entries,
      default: userInfo.role,
      id: 'role',
      onInput: onFocusFinish
    };

    const newEditFields = [
      {
        data: [{ header: 'User Info' }, f_name, l_name, email, phone, role]
      },
    ];

    editFields = newEditFields;

    setEditOpen(true);
  };
  /**
   * Sorts the list by either name, email, or role
   * @param {*} col Column
   */
  const onSortClick = (col) => {
    let localSorted = sorted;

    if (lastSorted != col) {
      localSorted = false;
      setSorted(false);
    }

    if (col == 'Last Name')
      setSearchData(Utils.sortUserDataArray(searchData, localSorted, 'last'));
    else if (col == 'First Name')
      setSearchData(Utils.sortUserDataArray(searchData, localSorted, 'first'));
    else if (col == 'Role')
      setSearchData(Utils.sortUserDataArray(searchData, localSorted, 'role'));
    else if (col == 'Email')
      setSearchData(Utils.sortUserDataArray(searchData, localSorted, 'email'));


    setLastSorted(col);
    setSorted(!sorted);
  };


  /**
 * Handles dynamic array filtering
 * @param {*} e contains text string value
 */
  const onSearchFilter = (e) => {
    setSearchPage(e ? true : false);
    setSearchData(Utils.filterDataArray(data, e));
    setSearchVal(e);
  };

  const onSearchFilterLinkPage = (e) => {
    setFilteredListData(Utils.filterDataArray(allData, e.target.value));
  };

  /**
   * When a home is being added, this function handles the form submission.
   * @param {*} event
   */
  const onAddHomeFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const address_1 = formData.get('home1');
    const address_2 = formData.get('home2');
    const city = formData.get('city');
    const zip = formData.get('zip');
    const state = formData.get('state');

    const home_info = { user_id: updateUserId, address_1, address_2, city, state, zip };

    setApiCall(true);
    const res = await ApiClient.post(Requests.addUserHomeInfo, { home_info });

    if (res.status == 200) {
      await fetch_evals();

      setHomeOpen(false);
      setApiCall(false);
    }

  };

  /**
 * A function Handler that handles the completion of the Add User form
 * @param {*} event the form that holds the info of the new evaluator
 */
  const onAddFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const email = formData.get('email');
    const first_name = formData.get('first');
    const last_name = formData.get('last');
    const phone = formData.get('phone');

    const raw_phone = phone.replace(/\D/g, '');

    const role = formData.get('role');
    const role_id = role_to_id[role];

    const address_1 = formData.get('home1');
    const address_2 = formData.get('home2');
    const city = formData.get('city');
    const zip = formData.get('zip');
    const state = formData.get('state');

    const address12 = formData.get('home12');
    const address22 = formData.get('home22');
    const city2 = formData.get('city2');
    const zip2 = formData.get('zip2');
    const state2 = formData.get('state2');

    const info = { first_name, last_name, email, phone: raw_phone, role_id };
    const house_info = { address_1, address_2, city, state, zip };

    setApiCall(true);
    const response = await ApiClient.post(Requests.createUser, info);

    if (response.data === 'NOT UNIQUE') {
      setNonUniqueErr('Email Exists');
      setApiCall(false);
    }

    // Will insert the user into the array rather than refreshing the entire page
    if (response.data !== 'NOT UNIQUE' && response.status == 200) {
      const user_id = response.data.id;

      house_info.user_id = user_id;

      if (role == 'Homeowner') {
        if (address12 != null) {
          const house_info2 = {};
          house_info2.address_1 = address12;
          house_info2.address_2 = address22;
          house_info2.city = city2;
          house_info2.state = state2;
          house_info2.zip = zip2;
          house_info2.user_id = user_id;
          await ApiClient.post(Requests.createHouse, house_info2);
          await ApiClient.post(Requests.createNonEval, house_info);
        } else {
          await ApiClient.post(Requests.createHouse, house_info);
        }

      }

      await fetch_evals();

      setOpen(false);
      setApiCall(false);
    }
  };

  /**
   * Handles the addition of a home
   * @param {*} event
   */
  const onHomeFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const address_1 = formData.get('home1');
    const address_2 = formData.get('home2');
    const city = formData.get('city');
    const state = formData.get('state');
    const zip = formData.get('zip');

    const home_info = { original_house_id: originalHomeData.house_id, user_id: updateUserId, address_1, address_2, city, state, zip, eval_id: userInfo.user_id };

    setApiCall(true);
    const response = await ApiClient.post(Requests.updateUserHomeInfo, { home_info });

    if (response.status == 200) {
      await fetch_evals();

      setEditHomeOpen(false);
      setApiCall(false);
    }
  };

  useEffect(() => {
    if (searchVal)
      onSearchFilter(searchVal);
  }, [data]);


  /**
 * A function handler that handles the completion of the edit form
 * @param {*} event the form that holds the info of the updated evaluator
 */
  const onEditFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const email = formData.get('email');
    const first_name = formData.get('first');
    const last_name = formData.get('last');
    const phone = formData.get('phone');
    const role = formData.get('role');

    const role_id = role_to_id[role];

    const raw_phone = phone.replace(/\D/g, '');

    const info = { id: updateUserId, first_name, last_name, email, phone: raw_phone, role_id };



    setApiCall(true);
    const response = await ApiClient.post(Requests.updateUser, info);

    if (response.status == 200) {
      await fetch_evals();

      setEditOpen(false);
      setApiCall(false);
    }
  };

  /**
   * Handles opening the home form.
   */
  const onAddHomeClick = (user_id) => {
    const newHomeownerFields = [
      {
        data: [
          { header: 'Address Info', onInput: onFocusFinish },
          { label: 'Address 1', id: 'home1', required: true, onInput: onFocusFinish },
          { label: 'Address 2', id: 'home2', required: false, onInput: onFocusFinish },
          { label: 'City', id: 'city', required: true, onInput: onFocusFinish },
          stateDropdown,
          { label: 'Zip', id: 'zip', required: true, onInput: onFocusFinish }
        ]
      }
    ];

    homeOwnerFields = newHomeownerFields;

    setUpdateUserId(user_id);
    setHomeOpen(true);
  };


  /**
   * Handles the form opening when linking a user to the evaluator.
   * @param {*} evid
   */
  const onMakeClick = async (evid) => {
    const ho_homereq = await ApiClient.get(Requests.getHousesByRole, { role_id: 1 });

    setEvaluatorSelected(evid);

    let rows = [];

    if (ho_homereq.status == 200) {
      ho_homereq.data.map(row => {
        rows.push(
          createUserHomeLink(row.first_name, row.last_name, row.address_1, row.city, row.state, row.user_id, row.house_id)
        );
      });

      setOpenList(true);
      setListData(rows);
      setFilteredListData(rows);
    }
  };

  /**
 * Grabs the evaluators info from the database to display in the table
 */
  const fetch_evals = async () => {
    let eval_homes = [];

    // All Evaluator Homes
    const eval_home_req = await ApiClient.get(Requests.getHousesByRole, { role_id: 2 });

    if (eval_home_req.status === 200) {
      eval_homes = [...eval_home_req.data];
    }

    const response = await ApiClient.get(Requests.createUser);

    if (response.status == 200) {
      const data = response.data;

      const data_objects = [];
      const all_data = [];

      data.map((user, idx) => {
        const homes = user.homes;

        let highestRole = Utils.getHighestRole(user.roles);
        if (highestRole > 1) return;

        user.highestRole = highestRole;

        const add = {
          type: 'button',
          inputButton: true,
          onClick: onAddHomeClick,
          onMake: onMakeClick,
        };


        let homeData = [];

        var to_delete = [];
        for (var z = 0; z < homes.length; z++) {
          if (homes[z].uh_deleted == 1) {
            to_delete.push(z);
          }
        }

        for (var k = to_delete.length - 1; k >= 0; k--) {
          homes.splice(to_delete[k], 1);
        }

        if (homes.length == 0) {
          homes.push({ uh_deleted: null, house_id: null, address_1: null, address_2: null, city: null, state: null, zip: null });
        }

        let hasHome = false;

        // Only include homes that are actually linked to the evaluator
        const filtered = homes.filter(elem => {
          let filteredHome = false;

          // Index through all of the evaluators linked homes
          eval_homes.map(h => {
            if (elem.house_id === h.house_id && userInfo.user_id == h.user_id) {
              filteredHome = true;
            }
          });

          return filteredHome ? elem : null;
        });

        filtered.map((home, idx2) => {
          const homeEdit = {
            label: 'Edit',
            type: 'edit',
            home: home,
            href: '#',
            user_id: user.user_id,
            userInfo: user,
            index: idx,
            onClick: onHomeownerEdit,
            onInput: onFocusFinish
          };

          const remove = {
            label: 'Unlink',
            href: '#',
            user_id: user.user_id,
            type: 'edit',
            home: home,
            onClick: onDelete,
            onInput: onFocusFinish
          };

          const address_1 = {
            label: home.address_1 ? home.address_1 : '-',
            href: `/home/${home.house_id}/0`,
            index: data.length,
            type: 'edit',
            onInput: onFocusFinish
          };

          let highRole = 1;
          user.roles.map((role) => {
            if (role.role_id > highRole) {
              highRole = role.role_id;
            }
          });


          if (home.address_1) {
            all_data.push(
              {
                address_1: home.address_1,
                address_2: home.address_2,
                house_id: home.house_id,
                city: home.city,
                state: home.state,
                zip: home.zip,
                first_name: user.first_name,
                last_name: user.last_name,
              }
            );
          }

          // Iterate through Evaluators homes, be sure this home is contained within the users table
          var name = '';
          eval_homes.map(h => {
            if (h.house_id == home.house_id) {

              // Make sure evaluation was done by this evaluator
              if (!(h.user_id === userInfo.user_id)) return;

              hasHome = true;
              name = `${h.first_name} ${h.last_name}`;
            }
          });

          if (idx2 == filtered.length - 1) {
            homeData.push(
              createHomeData(
                name || '-',
                address_1,
                home.address_2 || '-',
                home.city,
                home.state,
                home.zip,
                homeEdit,
                remove,
                add,
              )
            );
          } else {
            homeData.push(
              createHomeData(
                name || '-',
                address_1,
                home.address_2 || '-',
                home.city,
                home.state,
                home.zip,
                homeEdit,
                remove,
              )
            );
          }
        });

        const collapsible1 = {
          collapsible: true,
          headers: highestRole == 1 ? home_cols_ho : home_cols_eval,
          data: homeData,
          header: highestRole > 1 ? 'Homeowners' : 'Homes',
        };

        const formattedNumber = Utils.formatPhoneNumber(user.phone);

        if (highestRole < 2 && hasHome) {
          data_objects.push(
            createData(
              user.email,
              user.first_name,
              user.last_name,
              formattedNumber,
              role_entries[highestRole - 1],
              [collapsible1],
            )
          );
        } else if (hasHome) {
          data_objects.push(
            createData(
              user.email,
              user.first_name,
              user.last_name,
              formattedNumber,
              role_entries[highestRole - 1],
              [collapsible1],
            )
          );
        }
      });

      setData(data_objects);
      setAllData(all_data);
      setSearchData(data_objects);
      setDataLoaded(true);
    }
  };
  /**
   * Creates loading effect
   */
  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded && userInfo) {
      fetch_evals();
    }
  }, [loaded, userInfo]);
  /**
   * Creates the radio buttons to display home owners, evaluators, and boss evaluators
   */
  const handle_radio = (_, val) => {
    setCurrentRadioFilter(val);

    var temp = [];
    if (val === 'homeowners') {

      data.forEach((item) => {
        if (item.role == 'Homeowner') {
          temp.push(item);
        }
      });

    } else if (val === 'eval') {
      data.forEach((item) => {
        if (item.role == 'Evaluator' || item.role == 'Boss Evaluator') {
          temp.push(item);
        }
      });
    } else {
      data.forEach((item) => {
        temp.push(item);
      });
    }
    setSearchData(temp);
  };

  return (<>
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <img width='150' height='150' src={logo} alt="My Image" /><br></br>
    </div>
    <SessionNav /><br />
    <div style={{ width: '100%' }} className="homeowner">
      <ThemeProvider theme={theme}>
        {userLinkSuccess &&
          <Alert sx={{ mb: '5%' }} severity="success">
            <AlertTitle>User Successfully Link to Evaluator!</AlertTitle>
          </Alert>
        }
        <Typography variant='h4'>Homeowners</Typography><br />
        <div style={{ display: 'flex', marginBottom: '3%' }}>
          <SubmitButton sx={{ mr: '5%', whiteSpace: 'nowrap' }} label='Add Homeowner' onClick={() => setOpen(true)} />
          <SubmitButton sx={{ mr: '5%', whiteSpace: 'nowrap' }} label='Link Homeowner' onClick={() => onMakeClick(userInfo.user_id)} />
        </div>
        <FormDialog
          contextText={contextText}
          requiredText={'* Is a Required Field'}
          title={dialogTitle}
          open={open}
          buttonText={'Add'}
          errorMessage={notUniqueErr}
          onClose={() => {
            if (dataChanged) {
              setCloseConfirmationAddUser(true);
            } else {
              setOpen(false);
            }
          }}
          onSubmit={onAddFormSubmit}
          loading={api}
          fields={fields}
        />
        <FormDialog
          contextText={editText}
          requiredText={'* Is a Required Field'}
          title={contextEditTitle}
          open={editOpen}
          buttonText={'Update'}
          onClose={() => {
            if (dataChanged)
              setCloseConfirmationEditUser(true);
            else
              setEditOpen(false);
          }}
          onSubmit={onEditFormSubmit}
          loading={api}
          fields={editFields}
        />
        <FormDialog
          contextText={homeOwnerText}
          requiredText={'* Is a Required Field'}
          title={contextHomeownerEditTitle}
          open={editHomeOpen}
          buttonText={'Update'}
          onClose={() => {
            if (dataChanged)
              setCloseConfirmationEditHome(true);
            else
              setEditHomeOpen(false);
          }}
          onSubmit={onHomeFormSubmit}
          loading={api}
          fields={homeOwnerFields}
        >
        </FormDialog>
        <FormDialog
          contextText={'Add Home Information'}
          requiredText={'* Is a Required Field'}
          title={'Add Home'}
          open={addHomeOpen}
          buttonText={'Add'}
          onClose={() => {
            if (dataChanged)
              setCloseConfirmationAddHome(true);
            else
              setHomeOpen(false);
          }}
          onSubmit={onAddHomeFormSubmit}
          loading={api}
          fields={homeOwnerFields}
        >
        </FormDialog>
        {dataLoaded &&
          <>
            <Table
              rows={searchData}
              cols={cols}
              searchBar={true}
              onSearchFilter={onSearchFilter}
              onSortClick={onSortClick}
              loading={api}
              searchPage={search}
              pages
            />
          </>
        }
        {!dataLoaded &&
          <DataLoading dataFields={7} />
        }

        {/* Close Confirmation for the Edit Home tab */}
        <ConfirmationDialog
          text={CLOSE_CONFIRMATION}
          header={'Close Form'}
          onConfirm={() => { setCloseConfirmationEditHome(false); setEditHomeOpen(false); dataChanged = false; }}
          onClose={() => setCloseConfirmationEditHome(false)}
          loading={api}
          visible={closeConfirmationEditHome}
        />
        {/* Close Confirmation for Edit User tab */}
        <ConfirmationDialog
          text={CLOSE_CONFIRMATION}
          header={'Close Form'}
          onConfirm={() => { setCloseConfirmationEditUser(false); setEditOpen(false); dataChanged = false; }}
          onClose={() => setCloseConfirmationEditUser(false)}
          loading={api}
          visible={closeConfirmationEditUser}
        />
        {/* Close Confirmation for Add Home tab */}
        <ConfirmationDialog
          text={CLOSE_CONFIRMATION}
          header={'Close Form'}
          onConfirm={() => { setCloseConfirmationAddHome(false); setHomeOpen(false); dataChanged = false; }}
          onClose={() => setCloseConfirmationAddHome(false)}
          loading={api}
          visible={closeConfirmationAddHome}
        />
        {/* Close Confirmation for Add User tab */}
        <ConfirmationDialog
          text={CLOSE_CONFIRMATION}
          header={'Close Form'}
          onConfirm={() => { setCloseConfirmationAddUser(false); setOpen(false); dataChanged = false; }}
          onClose={() => setCloseConfirmationAddUser(false)}
          loading={api}
          visible={closeConfirmationAddUser}
        />

        <ConfirmationDialog
          visible={confirmDelete}
          text={delText}
          header='Unlink Homeowner'
          onClose={() => { setConfirmDelete(false); dataChanged = false; }}
          onConfirm={delUser}
          loading={api}
        />

        <ConfirmationDialog
          visible={confirmRemove}
          text={removeText}
          header='Remove Association'
          onClose={() => { setConfirmRemove(false); }}
          onConfirm={remUserHouse}
        />

        <List
          open={openList}
          data={filteredListData}
          onClick={onLinkToEvaluatorClicked}
          selected={currentListSelected}
          onCancel={() => setOpenList(false)}
          onSearch={onSearchFilterLinkPage}
          onLink={onLinkToEvaluatorSubmit}
          loading={api}
          success={linkSuccess}
        />
      </ThemeProvider>
    </div>
  </>);
};

export default EvaluatorMainPage;
