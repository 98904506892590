import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TagSelectionDialog = ({ open, tags, onClose, onTagSelect }) => {
  const [filteredTags, setFilteredTags] = useState([]);

  // Sync the filteredTags with the tags prop when the dialog opens
  useEffect(() => {
    if (open) {
      setFilteredTags(tags); // Show all tags when the dialog is opened
    }
  }, [open, tags]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = tags.filter((tag) => tag.tag.toLowerCase().includes(query));
    setFilteredTags(filtered);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Select a Tag
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Search Tags"
          variant="outlined"
          fullWidth
          onChange={handleSearch}
          sx={{ mb: 2 }}
        />
        <List>
          {filteredTags.map((tag) => (
            <ListItem
              key={tag.tag_id}
              button
              onClick={() => {
                onTagSelect(tag.tag); // Pass the selected tag to the parent
                onClose(); // Close the dialog
              }}
            >
              <ListItemText primary={tag.tag} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

TagSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Whether the dialog is open
  tags: PropTypes.array.isRequired, // Array of tags
  onClose: PropTypes.func.isRequired, // Function to handle closing the dialog
  onTagSelect: PropTypes.func.isRequired, // Function to handle tag selection
};

export default TagSelectionDialog;
