class Tree { }

function filterBranch(tree, search) {
  if (!tree) return { copy: null };

  const copy = { ...tree }; // Create a shallow copy of the node

  // If the current node matches the search, keep all its children
  if (copy.label.toLowerCase().includes(search)) {
    return { copy }; // Keep the node as-is with all its children
  }

  // Check if the node has children
  if (tree.children && tree.children.length > 0) {
    const filteredChildren = tree.children
      .map((child) => filterBranch(child, search).copy) // Recursively filter children
      .filter((child) => child !== null); // Remove null values

    if (filteredChildren.length > 0) {
      copy.children = filteredChildren;
      return { copy }; // Keep the node if it has matching children
    }
  }

  return { copy: null }; // Exclude non-matching nodes
}




Tree.getAllNodeIds = (node) => {
  if (!node) return [];
  let ids = [node.id];
  if (node.children) {
    node.children.forEach((child) => {
      ids = ids.concat(Tree.getAllNodeIds(child));
    });
  }
  return ids;
};



Tree.findNodeByString = (tree, searchStr) => {
  console.log('findNodeByString', tree, searchStr);
  if (!tree) {
    console.error('Tree is undefined or null.');
    return null;
  }

  if (tree.id === searchStr) {
    return tree; // Found the node
  }

  const stack = tree.children ? [...tree.children] : []; // Safeguard for undefined children

  while (stack.length > 0) {
    const node = stack.pop();

    if (node.id === searchStr) {
      return node; // Found the node
    }

    if (node.children) {
      stack.push(...node.children); // Add children to the stack for further traversal
    }
  }

  return null; // Node not found
};


Tree.filter = (tree, searchVal, rootNodes) => {

  console.log('Printing Tree Enter Filter', tree);
  console.log('Entering filter');

  if (!searchVal || !tree) {
    // If the search value is empty, return the original tree
    return { filteredTree: tree };
  }

  const { copy: filteredTree } = filterBranch(tree, searchVal.toLowerCase());

  console.log('Filtered Tree:', filteredTree);

  if (filteredTree === null) {
    console.log('nulltree');
    let copy = { ...tree };
    copy.children = [];
    return { filteredTree: copy };
  }

  // Return the filtered tree
  return { filteredTree: filteredTree };
};

function expandNested(node) {

}

Tree.expandAll = (tree, rootNodes, defExpanded) => {
  let expanded = [...defExpanded];

  rootNodes.forEach((nodeId) => {
    const actualNode = Tree.findNodeByString(tree, nodeId);

    if (!actualNode) return;

    const stack = [actualNode];

    while (stack.length > 0) {
      const node = stack.pop();
      expanded.push(node.id);

      if (node.children) {
        stack.push(...node.children);
      }
    }
  });

  return expanded;
};




Tree.collapseAll = (tree, rootNodes) => {
  const expanded = rootNodes;

  return expanded; // Only root nodes remain expanded
};


export default Tree;
