import React from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem2, useTreeItem2 } from '@mui/x-tree-view';
import PauseIcon from '@mui/icons-material/Circle';

import '../styles/animatePlay.scss';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import Typography from '@mui/material/Typography';

MultiSimpleSelectTreeView.propTypes = {
  tree: PropTypes.object,
  onExpandedItemsChange: PropTypes.func,
  currVideo: PropTypes.string,
  onSelectedItemsChange: PropTypes.func,
  expandedItems: PropTypes.array,
  selected: PropTypes.array,
  searchVal: PropTypes.string
};
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const { className, label, itemId, icon: iconProp, expansionIcon, displayIcon } = props;
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection } = useTreeItem2(itemId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, {
        expanded,
        selected,
        focused,
        disabled,
      })}
      ref={ref}
    >
      <div onClick={handleExpansionClick}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {selected && itemId.includes('mp4') && (
            <PauseIcon fontSize="small" sx={{ marginLeft: '10%' }} className="red-circle" />
          )}
        </div>
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  displayIcon: PropTypes.node,
  expansionIcon: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node,
  itemId: PropTypes.string.isRequired,
};

function renderTreeItems(items, level = 0) {
  if (!items) return null;

  const { label, children, color, id } = items;

  if (!children) {
    return (
      <TreeItem2
        key={id}
        itemId={id.toString()}
        label={label}
        level={level}
        sx={{ color: '#0000FF' }}
      />
    );
  }

  return (
    <TreeItem2
      key={id}
      itemId={id.toString()}
      label={label}
      sx={{ color }}
    >
      {children.map((child) => renderTreeItems(child, level + 1))}
    </TreeItem2>
  );
}

export default function MultiSimpleSelectTreeView(props) {
  return (
    <SimpleTreeView
      expansionTrigger="iconContainer"
      aria-label="rich object"
      expandedItems={props.expandedItems} // Corrected prop name
      selected={props.selected}
      onExpandedItemsChange={props.onExpandedItemsChange}
      onSelectedItemsChange={props.onSelectedItemsChange}
      sx={{ textAlign: 'left', mr: '5%' }}
    >
      {renderTreeItems(props.tree)}
    </SimpleTreeView>
  );
}
